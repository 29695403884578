import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import {
  Allergens,
  DineInCategories,
  DineInItem,
  useAddEdibleMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";
import swal from "sweetalert";
import { useFieldArray, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

interface prop {
  editData: DineInItem | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetchItems: () => void;
  handleEditClose: () => void;
  open: boolean;
  categories: Array<DineInCategories|null>|null |undefined
  allergens:Array<Allergens|null>|null|undefined
}

const DineInMenuForm = ({
  editData,
  propertyId,
  setOpen,
  refetchItems,
  handleEditClose,
  open,
  categories,
  allergens
}: prop) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    control,
    reset,
  } = useForm<DineInItem>({
    defaultValues: {
      desc: editData?.desc || "",
      name: editData?.name || "",
      type: editData?.type,
      price: editData?.price,
      isVegan: editData?.isVegan,
      extraOptions: editData?.extraOptions,
      img: editData?.img || "",
      allergens:editData?.allergens
    },
  });

  React.useEffect(() => {
     return reset(editData ||
    {
      desc: "",
      name: "",
      type: "",
      price: 0,
      isVegan: false,
      extraOptions: [],
      img: "",
      allergens:[]
    });
  }, [editData,reset]);

  const { fields, append, remove } = useFieldArray({
    name: "extraOptions",
    control,
  });

  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

  const [upsertEdible] =
    useAddEdibleMutation();

  const [image, setImage] = React.useState<any>("");
  const [uploadImg, setUploadImg] = React.useState<any>("");
  const [isDisable, setIsDisable] = React.useState(true);

  const onSubmit = async (data: DineInItem) => {
    let extraOption = data.extraOptions?.map((extra) => {
      if (extra?.__typename) {
        delete extra?.__typename;
      }
      return extra;
    });

    try {
      await upsertEdible({
        variables: {
          dineInItemInput: {
            id: editData?.id || "",
            desc: data.desc,
            img: image ? uploadImg : data.img,
            name: data.name,
            price: data.price,
            isVegan: data.isVegan,
            property_id: propertyId,
            type: data.type,
            extraOptions: extraOption,
            allergens:data.allergens,
            is_Activated:data?.is_Activated
          },
        },
      });

      swal({
        text: "Item Added Successfully",
        icon: "success",
      });
      refetchItems();
      setOpen(false);
      setImage("")
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadImg(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleEditClose}
    >
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Item" : "Add New Item"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Service*/}
            <Grid item md={12}>
              <TextField
                required
                fullWidth
                label="Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                rows={5}
                multiline
                label="Enter Description"
                {...register("desc")}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                type="number"
                min="0"
                required
                fullWidth
                label="Price"
                {...register("price", {
                  required: "Price is required!",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Category
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("type", {
                    required: "Category is required",
                  })}
                  label="Category"  
                  defaultValue={editData?.type}    
                 >
                  {categories?.map((category, index) => (
                    <MenuItem  key={index} value={category?.name}>
                      {category?.name}
                    </MenuItem>
                  ))} 
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Type{" "}
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("isVegan", {})}
                  label="Type"
                >
                  <MenuItem value={"true"}>Vegan</MenuItem>
                  <MenuItem value={"false"}>Non Vegan</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Allergen
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("allergens", {
                    // required: "allergens is required",
                  })}
                  label="Allergen"  
                  defaultValue={editData?.allergens?editData?.allergens:[]}  
                 >
                  {allergens?.map((allergen, index) => (
                    <MenuItem  key={index} value={allergen?.icon}>
                      {allergen?.name}
                    </MenuItem>
                  ))} 
                </Select>
              </FormControl>
            </Grid>
            {fields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item md={4}>
                      <TextField
                        required
                        fullWidth
                        label="Extras name"
                        {...register(`extraOptions.${index}.name`)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        required
                        fullWidth
                        label="Extras price"
                        {...register(`extraOptions.${index}.price`)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Button
                        sx={{ marginTop: "5px" }}
                        variant="outlined"
                        type="button"
                        startIcon={<Remove />}
                        onClick={() => remove(index)}
                      >
                        DELETE
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid />
            <Box>
              <Grid container spacing={2} mt={1} ml={0}>
                <Grid item md={6}>
                  <Button
                    variant="contained"
                    type="button"
                    size="large"
                    startIcon={<Add />}
                    onClick={() =>
                      append({
                        name: "",
                        price: 0,
                      })
                    }
                  >
                    Extras
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Grid item md={12} mb={2}>
              <Typography color="text.secondary" gutterBottom>
                Select Image
              </Typography>
              {loadingImage ? (
                <Box
                  sx={{
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "60px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                (editData?.img || image) && (
                  <Box>
                    <img
                      alt="Service"
                      width={"250px"}
                      src={image ? image : editData?.img}
                    />

                    {image && (
                      <Box
                        component={IconButton}
                        onClick={() => setImage(null)}
                      >
                        <Close />
                      </Box>
                    )}
                  </Box>
                )
              )}
              <Button variant="outlined">
                <label>
                  {editData ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty && isDisable}
              loading={false}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DineInMenuForm;
