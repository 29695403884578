import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Input,
  Stack,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import {
  HotelService,
  useAddServicesMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";
interface prop {
  editData: HotelService | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
}
const ServicesForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
}: prop) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset: serviceReset,
  } = useForm<HotelService>({
    defaultValues: {
      desc: editData?.desc || "",
      name: editData?.name || "",
      img: editData?.img || "",
    },
  });

  React.useEffect(() => {
    serviceReset(editData||
      {
        desc: "",
        name: "",
        img: "",
      }
      );
  }, [editData,serviceReset]);

  const [image, setImage] = React.useState<any>("");
  const [isDisable, setIsDisable] = React.useState(true);

  const [uploadImg, setUploadImg] = React.useState<any>("");
  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

  const [upsertHotelService] =
    useAddServicesMutation();

  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadImg(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };
  // Add Service Details
  const onSubmit = async (data: HotelService) => {
    try {
      const res = await upsertHotelService({
        variables: {
          hotelServicesInput: {
            id: editData?.id || "",
            desc: data.desc,
            name: data.name,
            img: image ? uploadImg : data.img,
            property_id: propertyId,
          },
        },
      });
      if (res.data?.upsertHotelService) {
        setOpen(false);
        setImage("")
        swal({
          text: "Service Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleEditClose}
    >
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Service" : "Add New Service"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Service*/}
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Service Name"
                {...register("name", {
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                rows={8}
                multiline
                label="Enter Description"
                {...register("desc", {
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12} mb={2}>
              <Typography color="text.secondary" gutterBottom>
                Select Image
              </Typography>
              <Box>
                {loadingImage ? (
                  <Box
                    sx={{
                      height: 100,
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "60px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  (editData?.img || image) && (
                    <Box>
                      <img
                        alt="Service"
                        width={"250px"}
                        src={image ? image : editData?.img}
                      />

                      {image && (
                        <Box
                          component={IconButton}
                          onClick={() => setImage(null)}
                        >
                          <Close />
                        </Box>
                      )}
                    </Box>
                  )
                )}
              </Box>
              <Button variant="outlined">
                <label>
                  {editData ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty && isDisable}
              loading={false}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ServicesForm;
