import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthProvider } from '../../core/authContext';
import {
  useGetUploadSignedUrlLazyQuery,
  useGetHotelInformationQuery,
  useUpdatePropertyMutation,
  useUpdateMetaDataMutation,
  useUpdateApiKeyMutation,
  useGetProperyDetailsQuery,
} from '../../generated/graphql';

import swal from 'sweetalert';

interface metaData {
  description: string;
  hotel_name: string;
  address: string;
  phone: string;
  email: string;
  feedback_desc: string;
  promotion_desc: string;
  aboutImg: string;
  directionImg: string;
  diningImg: string;
  serviceImg: string;
  roomsImg: string;
  directory_description: string;
}
interface propertyData {
  api_key: string;
  chat_url: string;
  domain: string;
  api_url: string;
  name: string;
  primary_color: string;
  primary_light: string;
  primary_text_color: string;
  secondary_text_color: string;
  img: string;
  currency:string;
  background_color:string;
}

const useHotelInformation = () => {
  const { getUser } = useAuthProvider();
  const property = getUser();

  const [upsertProperty] =
    useUpdatePropertyMutation();

  const [upsertMetaData] =
    useUpdateMetaDataMutation();



  const [upsertApiKey] = useUpdateApiKeyMutation();

  const [getUploadSignedURL] = useGetUploadSignedUrlLazyQuery({
    fetchPolicy: 'network-only',
  });

  //Get All Services
  const { data: hotelData, refetch:refetchData, loading: loadingHotel } =
    useGetHotelInformationQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: property?.id,
      },
    });


    const { data: propertyDetails, refetch:refetchProperty ,loading: loadingProperty } =
    useGetProperyDetailsQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: property?.id,
      },
    });

    
    const[isDisableProperty,setIsDisableProperty]=useState(true)
    const[isDisable,setIsDisable]=useState(true)
  // handle Image src


  
  const handlePropertyImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: any,
    field: string
  ) => {
    if (e.target.files != null) {
      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split('.')[1];
      const propertyID = property?.id;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': fileType,
            'Access-Control-Allow-Origin': '*',
          },
        });

        await setImage(field, uploadedImageURL);
        setIsDisableProperty(false)
      } catch (error) {
        swal({
          text: `${error}`,
          icon: 'error',
        });
      }
    }
  };




  const handleImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: any,
    field: string
  ) => {
    if (e.target.files != null) {
      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split('.')[1];
      const propertyID = property?.id;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': fileType,
            'Access-Control-Allow-Origin': '*',
          },
        });

        await setImage(field, uploadedImageURL);
        setIsDisable(false)
      } catch (error) {
        swal({
          text: `${error}`,
          icon: 'error',
        });
      }
    }
  };

  //property data form register
  const {
    register: propertyRegister,
    handleSubmit: handleSubmitProperty,
    formState: { errors: propertyError, isDirty: isDirtyProperty },
    getValues: getPropertyValues,
    setValue: setPropertyValue,
    watch: propertyWatch,
    reset,
  } = useForm<propertyData>({
    defaultValues: {
      api_key: '*********',
      name: propertyDetails?.getProperty?.name || '',
      domain: propertyDetails?.getProperty?.domain || '',
      api_url: propertyDetails?.getProperty?.hotlync_api_url || '',
      chat_url: propertyDetails?.getProperty?.chat_api_url || '',
      primary_color: propertyDetails?.getProperty?.primary_color|| '',
      primary_light: propertyDetails?.getProperty?.primary_light || '',
      primary_text_color: propertyDetails?.getProperty?.primary_text_color || '',
      secondary_text_color: propertyDetails?.getProperty?.secondary_text_color|| '',
      img: propertyDetails?.getProperty?.img|| '',
      currency:propertyDetails?.getProperty?.currency || '',
      background_color:propertyDetails?.getProperty?.background_color||''

      
    },
  });
  useEffect(() => {
    if (propertyDetails) {
      reset({
        api_key: '*********',
        name: propertyDetails?.getProperty?.name || '',
        domain: propertyDetails?.getProperty?.domain || '',
        api_url: propertyDetails?.getProperty?.hotlync_api_url || '',
        chat_url: propertyDetails?.getProperty?.chat_api_url || '',
        primary_color: propertyDetails?.getProperty?.primary_color|| '',
        primary_light: propertyDetails?.getProperty?.primary_light || '',
        primary_text_color: propertyDetails?.getProperty?.primary_text_color || '',
        secondary_text_color: propertyDetails?.getProperty?.secondary_text_color|| '',
        img: propertyDetails?.getProperty?.img|| '',
        currency:propertyDetails?.getProperty?.currency || '',
        background_color:propertyDetails?.getProperty?.background_color||''
      });
    }
  }, [propertyDetails,reset]);

  //Meta data Form Register
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue: setMetaValue,
    watch: metaWatch,
    reset:metaReset,
  } = useForm<metaData>({
    defaultValues: {
      hotel_name: hotelData?.getMeta?.hotel_name || '',
      description: hotelData?.getMeta?.about?.desc || '',
      email: hotelData?.getMeta?.dir_con?.email || '',
      address: hotelData?.getMeta?.dir_con?.address || '',
      phone : hotelData?.getMeta?.dir_con?.phone || '',
      feedback_desc: hotelData?.getMeta?.feedback_desc || '',
      promotion_desc: hotelData?.getMeta?.promotion_desc || '',
      aboutImg: hotelData?.getMeta?.about?.img || '',
      directionImg: hotelData?.getMeta?.dir_con?.img || '',
      diningImg: hotelData?.getMeta?.dining_image || '',
      serviceImg:hotelData?.getMeta?.hotel_services_image || '',
      roomsImg: hotelData?.getMeta?.rooms_image || '',
      directory_description : hotelData?.getMeta?.directory_description || ''
    }
  });

  useEffect(() => {
    if (hotelData) {
      metaReset({
        hotel_name: hotelData?.getMeta?.hotel_name || '',
        description: hotelData?.getMeta?.about?.desc || '',
        email: hotelData?.getMeta?.dir_con?.email || '',
        address: hotelData?.getMeta?.dir_con?.address || '',
        phone : hotelData?.getMeta?.dir_con?.phone || '',
        feedback_desc: hotelData?.getMeta?.feedback_desc || '',
        promotion_desc: hotelData?.getMeta?.promotion_desc || '',
        aboutImg: hotelData?.getMeta?.about?.img || '',
        directionImg: hotelData?.getMeta?.dir_con?.img || '',
        diningImg: hotelData?.getMeta?.dining_image || '',
        serviceImg:hotelData?.getMeta?.hotel_services_image || '',
        roomsImg: hotelData?.getMeta?.rooms_image || '',
        directory_description : hotelData?.getMeta?.directory_description || ''
      })
    }
  }, [hotelData,metaReset]);

  //Upsert Property
  const onSubmitProperty = async (data: propertyData) => {
    try {
      await upsertProperty({
        variables: {
          propertyInput: {
            id: property.id,
            name: data?.name,
            domain: data?.domain,
            chat_api_url: data.chat_url,
            hotlync_api_url: data?.api_url,
            primary_color: data?.primary_color,
            primary_light: data?.primary_light,
            primary_text_color: data?.primary_text_color,
            secondary_text_color: data?.secondary_text_color,
            img: data?.img,
            currency:data?.currency,
            background_color:data?.background_color
          },
        },
      });

      if (data.api_key !== '*********') {
        await upsertApiKey({
          variables: {
            apiKey: data?.api_key,
            propertyID: property.id,
          },
        });
        swal({
          text: 'Property and API Key Updated',
          icon: 'success',
        });
      } else {
        swal({
          text: 'Property Updated',
          icon: 'success',
        });
        refetchProperty()
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: 'error',
      });
    }
  };

  //Upsert Meta Data
  const onSubmitMetaData = async (metaData: metaData) => {
    try {
      await upsertMetaData({
        variables: {
          metaInput: {
            id: hotelData?.getMeta?.id,
            about: {
              desc: metaData?.description,
              img: metaData?.aboutImg,
            },
            dir_con: {
              img: metaData?.directionImg,
              phone: metaData?.phone,
              email: metaData?.email,
              address: metaData?.address,
            },
            dining_image: metaData?.diningImg,
            hotel_services_image: metaData?.serviceImg,
            rooms_image: metaData?.roomsImg,
            property_id: property.id,
            promotion_desc: metaData?.promotion_desc,
            hotel_name: metaData?.hotel_name,
            feedback_desc: metaData?.feedback_desc,
            directory_description: metaData?.directory_description,
          },
        },
      });

      swal({
        text: 'Information Updated',
        icon: 'success',
      });
      refetchData()
    } catch (err) {
      swal({
        text: `${err}`,
        icon: 'error',
      });
    }
  };

  return {
    Details:propertyDetails?.getProperty,
    propertyRegister,
    handleSubmitProperty,
    propertyError,
    loading:loadingProperty || loadingHotel,
    isDirtyProperty,
    onSubmitProperty,
    register,
    handleSubmit,
    onSubmitMetaData,
    errors,
    handleImage,
    setPropertyValue,
    getPropertyValues,
    setMetaValue,
    isDirty,
    handlePropertyImage,
    metaWatch,
    propertyWatch,
    isDisable,
    isDisableProperty,
  };
};

export default useHotelInformation;