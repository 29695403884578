import { Add, EditOutlined } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import useCategory from "./dine-in-category-hooks";
import CategoryForm from "./Dine-In-Categories-form";
import Loader from "../../components/Loader";
const HotelCategories = () => {
  const {
    Categories,
    handleClickEditOpen,
    editData,
    handleEditClose,
    open,
    propertyId,
    refetchCategory,
    setOpen,
    loadingCategories,
    handleDelete,
  } = useCategory();

  if (loadingCategories) {
    return <Loader />;
  }

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Category Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Category
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {Categories?.map((item: any, index: any) => (
          <Grid item md={3} lg={2} key={index}>
            <Card
              sx={{
                mt: 2,
                padding: 1,
                borderRadius: 3,
                position: "relative",
                height: "100px",
              }}
            >

             <CardContent sx={{ flex: 1, p: 1.5 }}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {item?.name}
                </Typography>
              </CardContent>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <Tooltip title="Edit" placement="top" arrow>
                  <IconButton
                    onClick={() => handleClickEditOpen(item)}
                    color="info"
                    aria-label="edit"
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete" placement="top" arrow>
                  <IconButton
                    onClick={() => handleDelete(item?.id)}
                    color="error"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Card>
          </Grid>
        ))}
        {/* Edit Dialog */}
        <CategoryForm
          editData={editData}
          handleEditClose={handleEditClose}
          open={open}
          propertyId={propertyId}
          refetch={refetchCategory}
          setOpen={setOpen}
        />
      </Grid>
    </>
  );
};

export default HotelCategories;
