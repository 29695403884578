import { Add, EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import useEdible from "./hotel-edible-hook";
import Loader from "../../components/Loader";
import DineInMenuForm from "./Dine-In-Menu-form";
import useHotelInformation from "../Hotel-Information/hotel-info-hook";
import useCategory from "../Dine-In-Categories/dine-in-category-hooks";
import useAllergen from "../Allregens/allergen-hooks";
import { groupBy } from "lodash";
import UiSwitch from "../../components/toggleButton/UiSwitch";
import { ALLERGEN_ICONS } from "../../components/constant/icons";
const HotelEdible = () => {
  const { Details } = useHotelInformation();
  const {
    edibleItems,
    loadingEdibleItems,
    handleClickEditOpen,
    handleEditClose,
    deleteEdibleItem,
    editData,
    open,
    propertyId,
    setOpen,
    refetchItems,
    onToggle,
  } = useEdible();
  const { Categories } = useCategory();
  const { Allergens } = useAllergen();

  const groupedMenu = groupBy(edibleItems, "type");

  if (loadingEdibleItems) {
    return <Loader />;
  }

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Edibles Item
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Item
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {Object.keys(groupedMenu)
          .sort()
          .map((key) => {
            return (
              <>
                <Typography
                  variant="h6"
                  style={{
                    marginLeft: "40px",
                    fontWeight: 600,
                    marginTop: "30px",
                  }}
                >
                  {key}
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  style={{ marginLeft: "12px" }}
                >
                  {groupedMenu[key]?.map((item) => {                    
                    return (
                      <Grid item md={3} lg={2} key={item?.id}>
                        <Card
                          sx={{
                            mt: 2,
                            padding: 1,
                            borderRadius: 3,
                            position: "relative",
                            height: "350px",
                          }}
                        >
                          <CardMedia
                            sx={{
                              "&.MuiCardMedia-img": {
                                height: 170,
                              },
                            }}
                            component="img"
                            image={item?.img}
                            style={{ borderRadius: "10px", objectFit: "cover" }}
                          />

                          <CardContent sx={{ flex: 1, p: 1.5 }}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600 }}
                            >
                              {item?.name}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 0.5 }}>
                              {Details?.currency} {item?.price}
                            </Typography>
                            <Stack
                              style={{marginTop:"2px",paddingRight:"70px"}}
                              direction="row"
                             alignItems="center"
                            > 
                           <Box
                              component="img"
                              style={{paddingRight:"7px"}}
                              alt={"veg"}
                              src={`/assets/${
                                item?.isVegan ? "veg" : "non-veg"
                              }.png`}
                            >
                            </Box>
                             {
                               item?.allergens?.map((all:any)=>{
                                 const icon = (ALLERGEN_ICONS as any)[all];
                                 return (<Typography sx={{pr:1}}>{icon}</Typography>)
                                })
                              }
                              </Stack>
                          </CardContent>
                          <UiSwitch
                            checked={item?.is_Activated!=null?item?.is_Activated:true}
                            onChange={() =>onToggle(item?.is_Activated!=null?!item?.is_Activated:false,
                                item?.id || ""
                              )
                            }
                            label={item?.is_Activated===true?"Activate":"Deactivate"}
                          />
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}
                          >
                            <Tooltip title="Edit" placement="top" arrow>
                              <IconButton
                                onClick={() => handleClickEditOpen(item)}
                                color="info"
                                aria-label="edit"
                              >
                                <EditOutlined />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top" arrow>
                              <IconButton
                                onClick={() =>
                                  deleteEdibleItem(item?.id as string)
                                }
                                color="error"
                                aria-label="delete"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            );
          })}
        {/* Edit Dialog */}
        <DineInMenuForm
          allergens={Allergens}
          categories={Categories}
          editData={editData}
          handleEditClose={handleEditClose}
          open={open}
          propertyId={propertyId}
          refetchItems={refetchItems}
          setOpen={setOpen}
        />
      </Grid>
    </>
  );
};

export default HotelEdible;