import * as React from "react";
import swal from "sweetalert";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetDineInCategoriesQuery,
  DineInCategories,
  useDeleteDineInCategoriesMutation,
} from "../../generated/graphql";
const useCategory = () => {
  const [editData, setEditData] = React.useState<DineInCategories | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleClickEditOpen = (data: DineInCategories | null) => {
    setEditData(data);
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditData(null);
    setOpen(false);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  // add categories
  const {
    data: Categories,
    refetch: refetchCategory,
    loading: loadingCategories,
  } = useGetDineInCategoriesQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });
  const [deleteCategories] = useDeleteDineInCategoriesMutation();

  // delete categories
  const handleDelete = async (id: string) => {
    try {
      await deleteCategories({
        variables: {
          id: id,
          propertyID: property?.id,
        },
      });
      swal({
        text: "Categories Deleted Successfully",
        icon: "success",
      });
      refetchCategory();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  return {
    Categories: Categories?.getDineInCategories,
    handleClickEditOpen,
    handleEditClose,
    editData,
    refetchCategory,
    propertyId: property?.id,
    open,
    setOpen,
    loadingCategories,
    handleDelete,
  };
};

export default useCategory;
