import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { EggIcon } from "../../components/icons/EggIcon";
import { FishIcon } from "../../components/icons/FishIcon";
import { MilkIcon } from "../../components/icons/MilkIcon";
import { PeanutsIcon } from "../../components/icons/PeanutsIcon";
import { ShelfishIcon } from "../../components/icons/Shelfishicon";
import { SoybeansIcon } from "../../components/icons/SoybeansIcon";
import { TreenutsIcon } from "../../components/icons/TreenutsIcon";
import { WheatIcon } from "../../components/icons/WheatIcon";
import {
    Allergens,
  useAddAllergensMutation,
} from "../../generated/graphql";

interface prop {
  editData: Allergens | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
}

const AllergenForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
}: prop) => {
  const icons = [
    {
      name: "FishIcon",
      icon: <FishIcon />,
    },
    {
      name: "MilkIcon",
      icon: <MilkIcon />,
    },
    {
      name: "TreenutsIcon",
      icon: <TreenutsIcon/>,
    },
    {
      name: "WheatIcon",
      icon: <WheatIcon/>,
    },
    {
      name: "ShelfishIcon",
      icon: <ShelfishIcon />,
    },
    {
      name: "PeanutsIcon",
      icon: <PeanutsIcon />,
    },
    {
      name: "SoybeansIcon",
      icon: <SoybeansIcon/>,
    },
    {
      name: "EggIcon",
      icon: <EggIcon/>,
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset: AllergenReset,
  } = useForm<Allergens>({
    defaultValues: {
      name: editData?.name || "",
      icon: editData?.icon || "",
    },
  });

  React.useEffect(() => {
    AllergenReset(
      editData || {
        name: "",
        icon: "",
      }
    );
  }, [editData, AllergenReset]);

  const [upsertAllergens] = useAddAllergensMutation();

  const onSubmit = async (data:Allergens) => {
    try {
      const res = await upsertAllergens({
        variables: {
          allergensInput: {
            id: editData?.id || "",
            name: data.name,
            icon: data.icon,
            property_id: propertyId,
          },
        },
      });
      if (res.data?.upsertAllergens) {
        setOpen(false);
        swal({
          text: "Allergen Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleEditClose}>
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Allergen" : "Add New Allergen"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Allergen form*/}
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Allergen Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Icon
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("icon", {
                    required: "icon  is required",
                  })}
                  label="Allergen Icon"
                  defaultValue={editData?.icon}
                >
                  {icons.map((ic, index) => (
                    <MenuItem key={index} value={ic.name}>
                      <ListItemIcon>{ic.icon}</ListItemIcon>
                      {ic.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty}
              loading={false}
              sx={{
                margin: "auto",
                marginTop: "25px",
              }}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AllergenForm;
