import { Routes, Route } from "react-router-dom";
import { memo } from "react";
import Layout from "../layout";
import HotelInformation from "./Hotel-Information";
import Room from "./Rooms";
import DineInMenu from "./Dining";
import HotelServices from "./Hotel-Services";
import HotelEdible from "./Dine-In-Menu";
import Promotions from "./Promotions";
import HotelCategories from "./Dine-In-Categories";
import HotelAllergens from "./Allregens";


const Routing = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HotelInformation />} />
        <Route path="/rooms" element={<Room />} />
        <Route path="/dining" element={<DineInMenu />} />
        <Route path="/hotel-services" element={<HotelServices />} />
        <Route path="/hotel-edible" element={<HotelEdible />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/categories" element={<HotelCategories/>} />
        <Route path="/allergens" element={<HotelAllergens/>} />
      </Routes>
    </Layout>
  );
};

export default memo(Routing);
