import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { AlcoholIcon } from "../../components/icons/AlcoholIcon";
import { BreakfastIcon } from "../../components/icons/BreakfastIcon";
import { DessertIcon } from "../../components/icons/DessertIcon";
import { DrinksIcon } from "../../components/icons/DrinksIcon";
import { HotDrinksIcon } from "../../components/icons/HotDrinksIcon";
import { MainsIcon } from "../../components/icons/MainsIcon";
import { SaladIcon } from "../../components/icons/SaladIcon";
import { SoupIcon } from "../../components/icons/SoupIcon";
import { StarterIcon } from "../../components/icons/StarterIcon";
import {
  DineInCategories,
  useAddDineInCategoriesMutation,
} from "../../generated/graphql";

interface prop {
  editData: DineInCategories | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
}

const CategoryForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
}: prop) => {
  const icons = [
    {
      name: "AlcoholIcon",
      icon: <AlcoholIcon />,
    },
    {
      name: "BreakfastIcon",
      icon: <BreakfastIcon />,
    },
    {
      name: "SaladIcon",
      icon: <SaladIcon />,
    },
    {
      name: "DessertIcon",
      icon: <DessertIcon />,
    },
    {
      name: "DrinksIcon",
      icon: <DrinksIcon />,
    },
    {
      name: "HotDrinksIcon",
      icon: <HotDrinksIcon />,
    },
    {
      name: "SoupIcon",
      icon: <SoupIcon />,
    },
    {
      name: "StarterIcon",
      icon: <StarterIcon />,
    },
    {
      name: "MainsIcon",
      icon: <MainsIcon />,
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset: CategoryReset,
  } = useForm<DineInCategories>({
    defaultValues: {
      name: editData?.name || "",
      icon: editData?.icon || "",
      desc:editData?.desc||""
    },
  });

  React.useEffect(() => {
    CategoryReset(
      editData || {
        name: "",
        icon: "",
        desc:""
      }
    );
  }, [editData, CategoryReset]);

  const [upsertCategories] = useAddDineInCategoriesMutation();

  const onSubmit = async (data: DineInCategories) => {
    try {
      const res = await upsertCategories({
        variables: {
          dineInCategoriesInput: {
            id: editData?.id || "",
            name: data.name,
            icon: data.icon,
            property_id: propertyId,
            desc:data?.desc
          },
        },
      });
      if (res.data?.upsertDineInCategory) {
        setOpen(false);
        swal({
          text: "Category Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleEditClose}>
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Category" : "Add New Category"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Category form*/}
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Category Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Category desc"
                {...register("desc", {
                  // maxLength: 60,
                  required: "description is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Icon
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("icon", {
                  })}
                  label="Category Icon"
                  defaultValue={editData?.icon}
                >
                  {icons.map((ic, index) => (
                    <MenuItem key={index} value={ic.name}>
                      <ListItemIcon>{ic.icon}</ListItemIcon>
                      {ic.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty}
              loading={false}
              sx={{
                margin: "auto",
                marginTop: "25px",
              }}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryForm;
