import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Loader from "../../components/Loader";

import useHotelInformation from "./hotel-info-hook";

const HotelInformation = () => {
  const {
    propertyRegister,
    handleSubmitProperty,
    isDirtyProperty,
    onSubmitProperty,
    register,
    handleSubmit,
    onSubmitMetaData,
    handleImage,
    setPropertyValue,
    setMetaValue,
    propertyWatch,
    loading,
    metaWatch,
    handlePropertyImage,
    isDirty,
    isDisable,
    isDisableProperty,
  } = useHotelInformation();
  const [showAPI, setShowAPI] = React.useState(false);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box component={"form"} onSubmit={handleSubmitProperty(onSubmitProperty)}>
        <Typography variant="h4" align="center" fontWeight={600}>
          App Setting
        </Typography>
        <Grid container spacing={1} pt={2}>
          <Grid item md={6}>
            <Grid container spacing={1} m={1}>
              <Grid item md={12}>
                <TextField
                  {...propertyRegister("api_key")}
                  fullWidth
                  disabled={showAPI ? false : true}
                  type={showAPI ? "text" : "password"}
                  InputLabelProps={{ shrink: true }}
                  label="API Key"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowAPI((show) => !show)}
                          edge="end"
                        >
                          {<EditIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Domain"
                  {...propertyRegister("domain", {
                    maxLength: 30,
                    required: "Domain is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="API URL"
                  {...propertyRegister("api_url", {
                    required: "Api url is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Chat API URL"
                  {...propertyRegister("chat_url", {
                    required: "chat api url is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Name"
                  {...propertyRegister("name", {
                    maxLength: 30,
                    required: "Hotel name is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Currency"
                  {...propertyRegister("currency", {
                    required: "Currency is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Color"
                  {...propertyRegister("primary_color", {
                    maxLength: 30,
                    required: "primary color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Light"
                  {...propertyRegister("primary_light", {
                    maxLength: 30,
                    required: "primary light is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_light")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                </Grid>
                <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Background Color"
                  {...propertyRegister("background_color", {
                    maxLength: 30,
                    required: "background is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("background_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={1} m={1}>
              <Grid item md={12} mb={2}>
                <Typography color="text.secondary" gutterBottom>
                  Select Logo
                </Typography>
                <Box>
                  <img
                    alt="logo"
                    width={"250px"}
                    height={"160px"}
                    src={propertyWatch("img")}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    Update Logo
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePropertyImage(e, setPropertyValue, "img")
                      }
                    />
                  </label>
                </Button>
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Text Color"
                  {...propertyRegister("primary_text_color", {
                    maxLength: 30,
                    required: "Primary text color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_text_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Secondary Text Color"
                  {...propertyRegister("secondary_text_color", {
                    maxLength: 30,
                    required: "Secondary text color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_text_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} m={2}>
            <Stack direction="row" spacing={2} margin="auto">
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!isDirtyProperty && isDisableProperty}
                loading={false}
              >
                Update Property
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box component={"form"} onSubmit={handleSubmit(onSubmitMetaData)}>
        <Typography variant="h4" align="center" fontWeight={600}>
          Hotel Information
        </Typography>
        <Grid container spacing={1} pt={2}>
          <Grid item md={6}>
            <Grid container spacing={1} m={1}>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Hotel Name"
                  {...register("hotel_name", {
                    required: "Hotel Name is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  rows={3.2}
                  multiline
                  label="Address"
                  {...register("address")}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  rows={3.2}
                  multiline
                  label="Directory Description"
                  {...register("directory_description")}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Email"
                  {...register("email")}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Phone"
                  {...register("phone")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={1} m={1}>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  rows={6}
                  multiline
                  label="Enter Description"
                  {...register("description")}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  rows={4}
                  multiline
                  label="Promotion Description"
                  {...register("promotion_desc")}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Feedback Description"
                  {...register("feedback_desc")}
                />
              </Grid>
              {/* Select Hotel Service Image */}
            </Grid>
          </Grid>

          <Grid container spacing={1} m={3} mt={1}>
            {/* About Image */}
            <Grid item lg={2} md={4}>
              <Typography color="text.secondary" gutterBottom>
                Select About Image
              </Typography>
              <Box>
                <img
                  alt="about img"
                  width={"150px"}
                  height={"100px"}
                  src={metaWatch("aboutImg") || "assets/defaultImg.jpg"}
                />
              </Box>
              <Button variant="outlined">
                <label>
                  update About
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleImage(e, setMetaValue, "aboutImg")
                    }
                  />
                </label>
              </Button>
            </Grid>
            {/* Select Direction Image */}
            <Grid item lg={2} md={4}>
              <Typography color="text.secondary" gutterBottom>
                Select Direction Image
              </Typography>
              <Box>
                <img
                  alt="direction img"
                  width={"150px"}
                  height={"100px"}
                  src={metaWatch("directionImg") || "assets/defaultImg.jpg"}
                />
              </Box>

              <Button variant="outlined" style={{ marginLeft: "-10px" }}>
                <label>
                  Update Direction
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleImage(e, setMetaValue, "directionImg")
                    }
                  />
                </label>
              </Button>
            </Grid>

            {/* Select Direction Image */}
            <Grid item lg={2} md={4}>
              <Typography color="text.secondary" gutterBottom>
                Select Dining Image
              </Typography>
              <Box>
                <img
                  alt="dining img"
                  width={"150px"}
                  height={"100px"}
                  src={metaWatch("diningImg") || "assets/defaultImg.jpg"}
                />
              </Box>
              <Button variant="outlined">
                <label>
                  update Dining
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleImage(e, setMetaValue, "diningImg")
                    }
                  />
                </label>
              </Button>
            </Grid>

            {/* Select Hotel Service Image */}
            <Grid item lg={2} md={4}>
              <Typography color="text.secondary" gutterBottom>
                Select Service Image
              </Typography>
              <Box>
                <img
                  alt="service img"
                  width={"150px"}
                  height={"100px"}
                  src={metaWatch("serviceImg") || "assets/defaultImg.jpg"}
                />
              </Box>
              <Button variant="outlined">
                <label>
                  update Service
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleImage(e, setMetaValue, "serviceImg")
                    }
                  />
                </label>
              </Button>
            </Grid>

            <Grid item lg={2} md={4}>
              {/* <Grid item md={3} mb={2}> */}
              <Typography color="text.secondary" gutterBottom>
                Select Rooms Image
              </Typography>
              <Box>
                <img
                  alt="room img"
                  width={"150px"}
                  height={"100px"}
                  src={metaWatch("roomsImg") || "assets/defaultImg.jpg"}
                />
              </Box>
              <Button variant="outlined">
                <label>
                  update Room
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleImage(e, setMetaValue, "roomsImg")
                    }
                  />
                </label>
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} m={2}>
            <Stack direction="row" spacing={2} margin="auto">
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!isDirty && isDisable}
                loading={false}
              >
                Update Data
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HotelInformation;
