import { Add, EditOutlined, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import useHotelServices from "./hotel-services-hook";
import Loader from "../../components/Loader";
import ServicesForm from "./Services-form";

const HotelServices = () => {
  const {
    servicesData,
    loadingServices,
    handleClickEditOpen,
    handleEditClose,
    open,
    editData,
    setOpen,
    refetch,
    propertyId,
    handleDelete,
  } = useHotelServices();

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Service Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Service
          </Button>
        </Grid>
      </Grid>
      {loadingServices ? (
        <Loader />
      ) : (
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {servicesData?.getMeta?.hotel_services?.map((service, index) => (
            <Grid item lg={4} md={6} key={index}>
              <Card
                sx={{ borderRadius: 0, position: "relative", height: "500px" }}
              >
                <Box
                  component={Button}
                  position="absolute"
                  top={5}
                  right={5}
                  variant="contained"
                  px={1}
                  minWidth="unset"
                  bgcolor={"#fff"}
                  onClick={() => handleClickEditOpen(service)}
                  color={"#000"}
                >
                  <EditOutlined />
                </Box>
                <CardMedia
                  sx={{
                    "&.MuiCardMedia-img": {
                      height: 250,
                    },
                  }}
                  component="img"
                  image={service.img}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {service.name}
                  </Typography>

                  <Typography variant="body1" color="text.secondary">
                    {service.desc}
                  </Typography>

                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    fontWeight={600}
                    mt={2}
                  ></Box>
                  <Tooltip title="Delete" placement="top" arrow>
                    <Box
                      component={Button}
                      position="absolute"
                      bottom={20}
                      right={15}
                      px={1}
                      minWidth="unset"
                      bgcolor={"#fff"}
                      onClick={() => handleDelete(service.id)}
                      color={"#E6170A"}
                    >
                      <Delete />
                    </Box>
                  </Tooltip>
                </CardContent>
              </Card>
            </Grid>
          ))}
          <ServicesForm
            editData={editData}
            handleEditClose={handleEditClose}
            open={open}
            propertyId={propertyId}
            refetch={refetch}
            setOpen={setOpen}
          />
          {/* Edit Dialog */}
        </Grid>
      )}
    </>
  );
};

export default HotelServices;
