import React from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetHotelServicesQuery,
  useDeleteHotelServiceMutation,
  HotelService,
} from "../../generated/graphql";

import swal from "sweetalert";

const useHotelServices = () => {
  const { getUser } = useAuthProvider();

  const property = getUser();

  const {
    data: servicesData,
    loading: loadingServices,
    refetch,
  } = useGetHotelServicesQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  const [editData, setEditData] = React.useState<HotelService | null>(null);

  const [open, setOpen] = React.useState(false);
  const handleClickEditOpen = (data: HotelService | null) => {
    setEditData(data);
    setOpen(true);
  };

  const handleEditClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const [deleteHotelService] =
    useDeleteHotelServiceMutation();

  const handleDelete = async (id: string) => {
    try {
      await deleteHotelService({
        variables: {
          id: id,
          propertyID: property.id,
        },
      });

      swal({
        text: "Service Deleted Successfully",
        icon: "success",
      });
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    servicesData,
    loadingServices,
    refetch,
    setOpen,
    handleClickEditOpen,
    handleEditClose,
    open,
    editData,
    propertyId: property.id,
    handleDelete,
  };
};

export default useHotelServices;
