import React from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetEdibleQuery,
  Types,
  useDeleteEdibleMutation,
  DineInItem,
  useUpdateEdibleStatusMutation,
} from "../../generated/graphql";

import swal from "sweetalert";

const useEdible = () => {
  const [editData, setEditData] = React.useState<DineInItem | null>(null);

  const [open, setOpen] = React.useState(false);

  const handleClickEditOpen = (data: DineInItem | null) => {
    setEditData(data);
    setOpen(true);
  };

  const handleEditClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  // Delete Mutation
  const [deleteEdible] = useDeleteEdibleMutation();
  //Get All Services
  const {
    data: edibleItems,
    loading: loadingEdibleItems,
    refetch: refetchItems,
  } = useGetEdibleQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });
  // change the status
  const [updateStatus] = useUpdateEdibleStatusMutation();

  const onToggle = async (checked: boolean, id: string) => {
    try {
      await updateStatus({
        variables: {
          id: id,
          propertyID: property?.id,
          status: checked,
        },
      });
      swal({
        text: "status change",
        icon: "success",
      });
      refetchItems();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const deleteEdibleItem = async (id: string) => {
    try {
      await deleteEdible({
        variables: {
          id: id,
          propertyID: property.id,
        },
      });

      swal({
        text: "Item Deleted Successfully",
        icon: "success",
      });
      refetchItems();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    edibleItems: edibleItems?.getEdibles,
    categories: [
      Types.Alcohol,
      Types.Breakfast,
      Types.Desserts,
      Types.Drinks,
      Types.HotDrinks,
      Types.Mains,
      Types.Salads,
      Types.Soups,
      Types.Starters,
    ],
    loadingEdibleItems,

    handleClickEditOpen,
    handleEditClose,
    deleteEdibleItem,
    open,
    editData,
    setOpen,
    refetchItems,
    propertyId: property.id,
    onToggle
  };
};

export default useEdible;
